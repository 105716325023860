import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src2510436823/src/clickcollect_frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/codebuild/output/src2510436823/src/clickcollect_frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_CaKIoANnI2 from "/codebuild/output/src2510436823/src/clickcollect_frontend/node_modules/nuxt/dist/app/plugins/router.js";
import payload_client_yVLowv6hDl from "/codebuild/output/src2510436823/src/clickcollect_frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/codebuild/output/src2510436823/src/clickcollect_frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2510436823/src/clickcollect_frontend/.nuxt/components.plugin.mjs";
import i18n_yfWm7jX06p from "/codebuild/output/src2510436823/src/clickcollect_frontend/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src2510436823/src/clickcollect_frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import flare_client_TQnyawnbiN from "/codebuild/output/src2510436823/src/clickcollect_frontend/plugins/flare.client.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_CaKIoANnI2,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  flare_client_TQnyawnbiN
]