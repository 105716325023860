<template>
    <div class="text-center h-full">
        <div class="flex flex-col gap-3 justify-center items-center h-full">
            <p class="text-highlight text-winther">404</p>
            <p class="text-primary text-title">Hov! Noget gik galt.</p>
            <p class="text-subtitle text-tertiary">Det ser ud til, at denne URL allerede er brugt.</p>
            <img class="max-w-max mx-auto mt-10" src="../assets/oc-collection.svg" alt="">
        </div>
    </div>
</template>

<script setup lang="ts">
</script>