<template>
    <div class="text-center h-full">
        <div class="flex flex-col gap-3 justify-center items-center h-full">
            <img class="max-w-max mx-auto mb-10" src="../assets/oc-throwing-teammate.svg" alt="">
            <p class="text-primary text-title">Din pakke er klar!</p>
            <p class="text-subtitle text-tertiary">For at hente din pakke skal du acceptere</p>
            <a class="text-winther underline text-subtitle -mt-3" href="https://www.homerunner.com/handelsbetingelser/" target="_blank" rel="noopener noreferrer">Homerunners brugervilkår.</a>
        </div>
        <div class="mt-auto max-w-lg mx-auto w-full px-5 pb-6 sticky bottom-0">
            <Button class="mt-16 text-center cursor-pointer" text="Acceptér brugervilkår" :loading="loading" @click="acceptTerms()"></Button>
        </div>
    </div>
</template>

<script setup lang="ts">
const loading = ref(false);
const emit = defineEmits(["accepted"]);

const acceptTerms = () => {
    emit("accepted");
}

</script>