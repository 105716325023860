import { flare } from "@flareapp/js";
import { flareVue } from "@flareapp/vue";


export default defineNuxtPlugin((nuxtApp) => {
	if (process.env.NODE_ENV === "production") {
    	flare.light(nuxtApp.$config.public.flareKey);
		flare.beforeSubmit = (report) => {
			return {
				...report,
				application_path: report.context.request?.url,
			};
		};
	};
    nuxtApp.vueApp.use(flareVue)
})
