<template>
    <div class="text-center h-full">
        <div class="flex flex-col gap-3 justify-center items-center h-full">
            <img class="max-w-max mx-auto mb-10" src="../assets/nc-5-star-review.svg" alt="">
            <p class="text-primary text-title">Succes!</p>
            <p class="text-subtitle text-tertiary">Tak fordi du handlede hos Winther studio</p>
        </div>
    </div>
</template>

<script setup lang="ts">
const loading = ref(false);

</script>