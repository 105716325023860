<template>
    <div class="text-center h-full">
        <div class="flex flex-col gap-3 justify-center items-center h-full">
            <img class="max-w-max mx-auto mb-10" src="../assets/oc-hi-five.svg" alt="">
            <p class="text-primary text-title">Klar, parat, pakke!</p>
            <p class="text-subtitle text-tertiary">Tryk på knappen for at åbne pakkeboksen. Sørg for, du er klar til at modtage din pakke!</p>
        </div>
        <div class="mt-auto mx-auto w-full pt-4 pb-6 px-5 sticky bottom-0">
            <Button :loading="loading" class="text-center cursor-pointer" text="Åben pakkeboks" @click="openDoor()"></Button>
        </div>
    </div>
</template>

<script setup lang="ts">
const props = defineProps<{
    loading: boolean;
}>();
const emit = defineEmits(["open"]);

const openDoor = () => {
    emit("open");
}

</script>