
<template>
  <div class="text-center h-full">
      <div class="flex flex-col gap-3 justify-center items-center h-full">
          <img class="max-w-max mx-auto mb-10" src="../assets/ec-pick-up-at-the-exact-location.svg" alt="">
          <p class="text-primary text-title">Bekræft placering</p>
          <p class="text-subtitle text-tertiary">For at åbne pakkeboksen skal du enten acceptere tracking ved at verificere din lokation eller bekræfte, at du er tæt på boksen.</p>
          <div class="mt-5">
            <Button
              @click="confirmLocation()"
              :loading="loading"
              class="text-center cursor-pointer"
              text="Acceptér tracking"
            >
            </Button>
            <Button @click="denyLocation()" :loading="loading" type="ghost" class="text-center mt-2" :text="t('im_close')"> </Button>
          </div>
      </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  signature: string;
}>();
const config = useRuntimeConfig();
const { t } = useI18n();
const emit = defineEmits(["update:modelValue"]);

const cordError = ref(false);
const distanceError = ref(false);
const boxMissing = ref(false);
const loading = ref(false);

function confirmLocation() {
  loading.value = true;
  if ("permissions" in navigator) {
    navigator.permissions
      .query({
        name: "geolocation",
      })
      .then((permissionStatus) => {
        permissionStatus.onchange = () => {
          if (permissionStatus.state === "granted") {
            getCurrentPosition();
          } else if (permissionStatus.state === "denied") {
            distanceError.value = true;
            loading.value = false;
          }
        };

        if (permissionStatus.state === "denied") {
          distanceError.value = true;
          loading.value = false;
        } else {
          getCurrentPosition();
        }
      })
  } else {
    distanceError.value = true;
    loading.value = false;
  }
}
function denyLocation() {
  emit("update:modelValue", {
    latitude: 0,
    longitude: 0,
  });
}

function getCurrentPosition() {
  navigator.geolocation.getCurrentPosition(checkDistance, (err) => {
    cordError.value = true;
    loading.value = false;
  });
}

function checkDistance(pos: GeolocationPosition) {
  $fetch(`${config.public.apiBase}/locker/distance/${props.signature}`, {
    method: "post",
    body: {
      lat: pos.coords.latitude,
      long: pos.coords.longitude,
    },
    onResponse({ request, response, options }) {
      if (response.status == 404) {
        boxMissing.value = true;
        loading.value = false;
        return;
      }
      if (response._data.out_of_bounds) {
        distanceError.value = true;
        loading.value = false;
      } else {
        emit("update:modelValue", {
          latitude: pos.coords.latitude,
          longitude: pos.coords.longitude,
        });
        loading.value = true;
      }
    },
    onResponseError({ request, response, options }) {
      loading.value = false;
    },
  });
}
</script>
