<template>
    <div class="w-full flex items-center justify-center gap-3 overflow-hidden transition-all duration-300 my-1">
        <div class="h-[18px] w-[18px] bg-primary rounded-full animateCircle group-hover:bg-white" :style="`--delay: ${index / 10 + 0.3}s`" v-for="circle, index in numberOfCircles" :key="index"></div>
    </div>
</template>

<script setup lang="ts">
    const numberOfCircles = ref(5);
</script>

<style>
.animateCircle {
    animation: 1.2s ease-in-out var(--delay) infinite loader;
}

@keyframes loader {
    0% {
        opacity: 0.05;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.05;
    }
}
</style>