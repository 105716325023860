<template>
  <div class="w-dvw mx-auto h-dvh pt-8 px-5 relative overflow-hidden grid grid-rows-[50px_1fr]">
    <img class=" max-w-max mx-auto" src="./assets/wintherstudio.svg" alt="">
    <div v-if="keyError" class="h-full flex flex-col items-center">
      <Error></Error>
    </div>
    <div v-if="!keyError" class="w-full relative">
        <Success class="w-full h-full absolute top-0 left-0 transition-all duration-500" :class="step == 4 ? 'opacity-100' : 'opacity-0 pointer-events-none'"></Success>
        <div class="w-full h-full absolute top-0 transition-all duration-500 left-0 bg-primary delay-500" :class="step == 3 ? 'opacity-100' : 'opacity-0 pointer-events-none'"></div>
        <Open @open="openDoor()" :loading="loading" class="w-full h-full absolute top-0 left-0 transition-all duration-500" :class="[step == 3 ? 'opacity-100' : 'opacity-0 pointer-events-none', previousStep == 3 ? 'left-10':'']"></Open>
        <div class="w-full h-full absolute top-0 transition-all duration-500 left-0 bg-primary delay-500" :class="step == 2 ? 'opacity-100' : 'opacity-0 pointer-events-none'"></div>
        <Location  @update:model-value="val => setLatLong(val)" :signature="signature" class="w-full h-full absolute top-0 transition-all duration-500 left-0" :class="[step == 2 ? 'opacity-100' : 'opacity-0 pointer-events-none', previousStep == 2 ? 'left-10':'']"></Location>
        <div class="w-full h-full absolute top-0 transition-all duration-500 left-0 bg-primary delay-500" :class="step == 1 ? 'opacity-100' : 'opacity-0 pointer-events-none'"></div>
        <Terms @accepted="step = 2" class="w-full h-full absolute top-0 left-0 transition-all duration-500" :class="step == 1 ? 'opacity-100' : 'opacity-0 pointer-events-none left-10'"></Terms>
    </div>
  </div>
</template>

<script setup lang="ts">
  const config = useRuntimeConfig()
  const route = useRoute()
  const keyError = ref(false)
  const latitude = ref(0);
  const longitude = ref(0);
  const step = ref(1);
  const boxId = ref('');
  const signature = ref('');
  const codeError = ref(false);
  const loading = ref(false);

  const previousStep = computed(() => {
    return step.value - 1;
  });

  onMounted(() => {
    signature.value = route.query.signature;
    if (signature.value) {
      validateKey();
    }
  })

  function setLatLong(val: { latitude: number; longitude: number }) {

    latitude.value = val.latitude;
    longitude.value = val.longitude;
    step.value = 3;
  }
  function validateKey() {
    $fetch(`${config.public.apiBase}/locker/consumer/validate/${signature.value}`, {
      method: 'get',
      onResponse({ request, response, options }) {
        if (response._data.status == 'success') {
          boxId.value = response._data.data.box_id;
          return
        } else {
          keyError.value = true;
        }
      },
    })
  }

  function openDoor() {
    loading.value = true;
    $fetch(`${config.public.apiBase}/locker/consumer/pickup/${signature.value}`, {
      method: 'get',
      onResponse({ request, response, options }) {
        console.log(response.status);
        if (response.status !== 200) {
          codeError.value = true;
          loading.value = false;
        } else {
          step.value = 4;
          loading.value = false;
        }
      },
    })
  }
</script>
